
/* Aggressive and chaotic glitch effect */
.aggressive-glitch {
  position: relative;
  animation: glitch 0.1s infinite;
  color: lime;
  font-family: monospace;
}

.aggressive-glitch::before,
.aggressive-glitch::after {
  content: attr(data-text); /* This allows the use of text content for glitch effect */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  animation: glitchBefore 0.15s infinite;
  clip: rect(0, 900px, 0, 0); /* Control which part of the text is being clipped */
}

.aggressive-glitch::after {
  animation: glitchAfter 0.2s infinite;
  text-shadow: -2px 0 red, 2px 0 blue; /* Stronger shadow for more aggressive effect */
}

@keyframes glitch {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-2px, 2px) skew(0.3deg);
  }
  40% {
    transform: translate(2px, -2px) skew(-0.3deg);
  }
  60% {
    transform: translate(-3px, -1px) skew(0.5deg);
  }
  80% {
    transform: translate(2px, 3px) skew(-0.5deg);
  }
  100% {
    transform: translate(0);
  }
}

@keyframes glitchBefore {
  0% {
    clip: rect(20px, 9999px, 85px, 0);
    transform: translate(-5px, -5px);
  }
  50% {
    clip: rect(0px, 9999px, 45px, 0);
    transform: translate(5px, 5px);
  }
  100% {
    clip: rect(10px, 9999px, 70px, 0);
    transform: translate(-3px, -2px);
  }
}

@keyframes glitchAfter {
  0% {
    clip: rect(40px, 9999px, 100px, 0);
    transform: translate(5px, 5px);
  }
  50% {
    clip: rect(0px, 9999px, 60px, 0);
    transform: translate(-7px, -2px);
  }
  100% {
    clip: rect(30px, 9999px, 90px, 0);
    transform: translate(6px, 3px);
  }
}
